import { lazy } from "react";
const AdminAnnouncements = lazy(() => import("./Announcements/AdminAnnouncements"));
const AnnouncementForm = lazy(() => import("./Announcements/AnnouncementForm"));

const AdminAuthors = lazy(() => import("./Authors/AdminAuthors"));
const AuthorForm = lazy(() => import("./Authors/AuthorForm"));

const AdminBooks = lazy(() => import("./Books/all"));
const AddBookForm = lazy(() => import("./Books/add"));
const EditBookForm = lazy(() => import("./Books/edit"));

const AdminCategories = lazy(() => import("./Categories/AdminCategories"));
const CategoryForm = lazy(() => import("./Categories/CategoryForm"));

const AdminComments = lazy(() => import("./Comments/all"));
const CommentForm = lazy(() => import("./Comments/single"));
const AdminReportedComments = lazy(() => import("./Comments/Reported/Comments"));
const AdminRepliesReported = lazy(() => import("./Comments/Reported/Replies"));

const AdminDashboard = lazy(() => import("./Dashboard/AdminDashboard"));

const AdminSerieses = lazy(() => import("./Serieses/AdminSerieses"));
const SerieForm = lazy(() => import("./Serieses/SerieForm"));

const AdminUsers = lazy(() => import("./Users/all"));
const AdminSupport = lazy(() => import("./Support/all"));
const UserForm = lazy(() => import("./Users/single"));

const AdminPlans = lazy(() => import("./Plans/AdminPlans"));

const AdminTransactions = lazy(() => import("./Transactions/all"));

const StatisticsScreen = lazy(() => import("./Statistics"));
const SettingsScreen = lazy(() => import("./Settings"));

export {
  AdminAnnouncements,
  AnnouncementForm,
  AdminAuthors,
  AuthorForm,
  AdminBooks,
  AddBookForm,
  EditBookForm,
  AdminCategories,
  CategoryForm,
  AdminComments,
  AdminReportedComments,
  AdminRepliesReported,
  CommentForm,
  AdminDashboard,
  AdminSerieses,
  SerieForm,
  AdminUsers,
  AdminSupport,
  UserForm,
  AdminPlans,
  AdminTransactions,
  StatisticsScreen,
  SettingsScreen,
};
