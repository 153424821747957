import { axios, requests } from "../../index";

const adminStatisticsLoader = async () => {
  const { data } = await axios.get(`${requests.admin.statistics}`);
  if (data && data.statics) {
    return data.statics;
  }
  return { from: "", to: "", timeSpent: [] };
};

export default adminStatisticsLoader;
